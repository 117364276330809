<template>
  <div v-if="is_ready == 1">
    <OrdersForm
      :type="'edit'"
      :order="order"
      @save="updateOrder"
      @invoiced="getCurrentOrder"
      @setOrderReceipts="setOrderReceipts"
    />
  </div>
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    OrdersForm: () => import('./OrdersForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    order: null,
    is_ready: 0,
    askToLeave: true,
    default_delivery_date: null,
  }),
  methods: {
    getCurrentOrder() {
      this.$api
        .get(`order/${this.$route.params.id}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.order = res.data.data
          this.order.budget.client.client_contact = JSON.parse(
            this.order.budget.client.client_contact
          )
          this.order.budget.client.client_address = JSON.parse(
            this.order.budget.client.client_address
          )
          this.default_delivery_date = res.data.data.delivery_date
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.is_ready = 1
        })
    },
    setOrderReceipts(value) {
      this.order.receipts = value
    },
    updateOrder() {
      if (!this.order.print_name) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar atualizar este Pedido',
          caption: 'Campo Gravação é obrigatório!',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else if (!this.order.print_tag) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar atualizar este Pedido',
          caption: 'Campo Etiqueta é obrigatório!',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else if (!this.order.delivery_date) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar atualizar este Pedido',
          caption: 'Campo Data de Entrega é obrigatório!',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else {
        this.askToLeave = false

        // Formatando nova data de entrega
        this.order.delivery_date = this.order.delivery_date
          .split('/')
          .reverse()
          .join('-')
        this.order.delivery_date = new Date(
          this.order.delivery_date
        ).toISOString()
        this.order.delivery_date = this.order.delivery_date.slice(0, 10)

        this.order.client_address = JSON.stringify(this.order.client_address)

        this.$api
          .patch('orders', this.order)
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Pedido atualizado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'orders' })
              },
            })
          })
          .catch((err) => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar atualizar este Pedido',
              caption:
                err.response.data.message ||
                'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      }
    },
  },
  created() {
    this.getCurrentOrder()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
